/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-indent */
import React, { useCallback, useState } from 'react';

const SeoFooter = () => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = useCallback(() => {
    setIsReadMore((prev) => !prev);
  }, []);

  return (
    <>
      <section className="section-container h-auto">
        <div
          className={`font-normal text-sm mt-3 text-justify ${
            isReadMore ? 'h-[250px] overflow-hidden' : 'h-auto'
          }`}
        >
          <h1 className="font-semibold text-base">
            Situs Pengadaan Barang dan Jasa Terlengkap di Indonesia
          </h1>
          <br />
          <strong>Pengadaan barang dan jasa</strong> merupakan suatu kegiatan yang dilakukan untuk
          memperoleh barang atau jasa. Proses pengadaan barang dan jasa dimulai dari perencenaan
          kebutuhan sebuah perusahaan, lembaga, organisasi ataupun sebuah perkumpulan komunitas.
          Selanjutnya, kegiatan akan terus dilakukan sampai mendapatkan barang ataupun jasa yang
          sesuai dengan perencanaan kebutuhan tadi. Pengadaan barang dan jasa pada sektor perusahaan
          swasta cenderung lebih mudah dibandingkan pada sektor pemerintahan. Hal ini sangat wajar
          mengingat pengadaan barang dan jasa pada sektor pemerintahan berkaitan dengan APBN
          (Anggaran Pendapatan dan Belanja Negara) ataupun APBD (Anggaran Pendapatan dan Belanja
          Daerah). Pada sektor swasta, pengadaaan barang dan jasa cenderung lebih mudah karena
          mengacu pada kebijakan instansi atau perusahaan masing-masing.
          <br />
          <br />
          Dalam pelaksanaan proses pengadaan barang dan jasa baik pada sektor pemerintah ataupun
          swasta, ada beberapa prinsip dasar pengadaan barang dan jasa yang harus diikuti. Prinsip
          dasar pengadaan barang dan jasa tersebut bertujuan sebagai pedoman atau landasan dalam
          pelaksanaan kegiatan pengadaan barang dan jasa. Berikut beberapa prinsip dasar pengadaan
          barang dan jasa:
          <br />
          <br />
          <strong>1. Efisien</strong>
          <br />
          <br />
          Pengadaan barang dan jasa harus bersifat efisien dalam artian diupayakan sebisa mungkin
          untuk menggunakan sumber daya dan biaya seminimal mungkin tapi dengan fungsi dari barang
          dan jasa yang maksimal. Biaya seminimal mungkin ini tidak hanya menyangkut tentang harga
          barang ataupun jasa, tetapi juga usia barang ataupun jasa serta ketersediaan barang serta
          suku cadangnya. Selain itu, efisien di sini juga bersangkutan dengan metode pengadaan
          karena berkaitan dengan kondisi dan waktu yang sesuai dengan kebutuhan perusahaan. Apabila
          salah dalam pemilihan metode, maka dapat dipastikan akan terjadi pemborosan biaya dan
          waktu.
          <br />
          <br />
          <strong>2. Efektif</strong>
          <br />
          <br />
          Prinsip efektif pada proses pengadaan barang dan jasa harus sesuai dengan kebutuhan yang
          telah ditetapkan sebuah perusahaan, lembaga, organisasi ataupun komunitas sesuai dengan
          perencanaan awal. Efektif di sini memiliki artian bahwa barang atau jasa yang diperoleh
          memiliki nilai manfaat dan nilai pakai yang tinggi bagi keberlangsungan perusahaan,
          lembaga, organisasi ataupun komunitas. Prinsip efektif bisa dinilai berdasarkan penyerahan
          barang atau jasa yang tepat waktu, kualitas terbaik dari barang dan jasa, kuantitas yang
          memadai, mampu bersinergi dengan barang dan jasa lainnya serta mampu memberikan dampak
          yang optimal terhadap keseluruhan kebijakan atau program yang dirancang oleh perusahaan,
          lembaga, organisasi ataupun komunitas.
          <br />
          <br />
          <strong>3. Akuntabel</strong>
          <br />
          <br />
          Prinsip terakhir yang harus dipenuhi tentu saja adalah akuntabel, dimana dari segi
          keuangan, proses pengadaan barang dan jasa dapat ditelusuri dan dipertanggung jawabkan
          dengan baik dan benar sesuai dengan nilai moral dan etika yang berlaku. Akuntabel di sini
          juga bersifat dimana proses pengadaan barang dan jasa harus mencapai sasaran, baik fisik,
          keuangan maupun manfaat bagi kelancaran pelaksanaan tugas. Oleh karenanya, proses
          pengadaan barang dan jasa harus diarsip dengan baik dan detail, ada juga proses pengawasan
          pengadaan barang dan jasa hingga mekanisme untuk proses evaluasi, peninjauan, penelitian
          hingga pengambilan tindakan atau intervensi proses pengadaan barang dan jasa yang tidak
          sesuai dengan peraturan dan kebijakan yang berlaku.
          <br />
          <br />
          Dengan prinsip-prinsip di atas, maka proses pengadaan barang dan jasa dapat dilakukan dan
          dijalankan dengan benar karena sesuai dengan kebutuhan yang dibutuhkan oleh perusahaan,
          lembaga, organisasi ataupun komunitas. Perusahaan, lembaga, organisasi ataupun komunitas
          pun juga dapat melakukan penunjukan langsung dalam melakukan pengadaan barang dan jasa.
          Oleh karenanya, mereka dapat melakukan pengadaan barang dan jasa secara online melalui
          beberapa situs pengadaan barang dan jasa yang tersedia. Hal ini tentu saja dapat
          memudahkan perusahaan, lembaga, organisasi serta komunitas yang bergerak secara swasta
          dalam memenuhi berbagai kebutuhan mereka. Dengan melakukan penunjukan langsung melalui
          situs pengadaan barang dan jasa secara online, mereka memiliki arsip yang lebih jelas,
          daftar transaksi yang lebih detail serta penghematan waktu yang lebih singkat dibandingkan
          sistem lelang.
          <br />
          <br />
          Xooply sendiri merupakan salah satu situs pengadaan barang dan jasa yang secara resmi
          berdiri pada tahun (2016). Situs suplai barang dan jasa ini merupakan produk dari PT.
          Metranet yang berdomisili di Jakarta. Kelahiran teknologi dan pemanfaatannya di era
          digital inilah yang menjadi salah satu sebab kelahiran Xooply yang memiliki tujuan untuk
          memenuhi berbagai kebutuhan perusahaan, lembaga, organisasi ataupun komunitas yang
          bergerak secara swasta. Dengan adanya Xooply, berbagai perusahaan, lembaga, organisasi
          ataupun komunitas dapat melakukan pengadaan barang dan jasa dengan metode penunjukan
          langsung kepada vendor-vendor ataupun supplier yang tersedia di Xooply. Banyak sekali
          kategori pengadaan barang dan jasa yang ditawarkan oleh Xooply, seperti;
          <br />
          <br />
          <a
            className="text-theme-primary-main underline cursor-pointer"
            href="https://xooply.id/c/alat-tulis-kantor"
          >
            1. Alat Tulis Kantor;
          </a>
          <br />
          <br />
          2. Perlengkapan Kantor;
          <br />
          <br />
          3. Aksesoris Komputer;
          <br />
          <br />
          <a
            className="text-theme-primary-main underline cursor-pointer"
            href="https://xooply.id/c/peralatan-elektronik"
          >
            4. Peralatan Elektronik;
          </a>
          <br />
          <br />
          5. Jasa Custom Produk;
          <br />
          <br />
          6. Alat Kesehatan & Kecantikan;
          <br />
          <br />
          <a
            className="text-theme-primary-main underline cursor-pointer"
            href="https://xooply.id/c/barang-konsumsi/bahan-makanan-minuman"
          >
            7. Makanan & Minuman;
          </a>
          <br />
          <br />
          8. Alat Pelindung Diri;
          <br />
          <br />
          9. Program Pelatihan Karyawan.
          <br />
          <br />
          Segera penuhi kebutuhan perusahaan ada dengan melakukan pengadaan barang dan jasa
          terpercaya di Xooply!
          <br />
          <br />
          <br />
          <h2 className="font-semibold text-base">
            Belanja Barang dan Jasa Makin Mudah Bersama Xooply
          </h2>
          <br />
          Mengapa harus melakukan belanja barang dan jasa melalui situs pengadaan barang dan jasa
          Xooply? Melalui Xooply, Anda dapat melakukan belanja barang dan jasa secara digital
          sehingga lebih cepat, transparan dan tentunya dapat meningkatkan efisiensi dalam proses
          belanja barang dan jasa. Anda tidak perlu mengeluarkan sumber daya yang lebih seperti
          biaya yang besar ataupun tenaga yang banyak layaknya sistem lelang. Cukup perusahaan Anda
          tinggal menentukan orang-orang yang layak di perusahaan Anda sebagai person in charge
          (PIC) dalam menggunakan akun Xooply perusahan Anda. Selanjutnya, orang-orang itulah yang
          nantinya akan mencari barang dan jasa yang sesuai dengan kebutuhan perusahaan Anda. Untuk
          proses belanja barang dan jasa di Xooply pun tentunya membutuhkan izin dari penanggung
          jawab akun, sehingga PIC yang terlibat dalam penentuan belanja barang dan jasa tidak bisa
          seenaknya memilih barang dan jasa tanpa pertimbangan dan sepengetahuan penanggung jawab.
          Selain itu, masih banyak lagi keuntungan yang bisa Anda dapatkan dari belanja barang di
          Xooply, seperti;
          <br />
          <br />
          <strong>1. Fitur Monitoring & Tracking</strong>
          <br />
          <br />
          Situs belanja barang dan jasa Xooply memiliki fitur tracking and monitoring untuk setiap
          proses belanja barang dan jasa. Fitur ini tentunya memudahkan setiap perusahaan, lembaga,
          organisasi ataupun komunitas yang melakukan belanja barang dan jasa melalui Xooply. Mereka
          bisa melihat dengan jelas proses belanja barang dan jasa yang mereka lakukan, mulai dari
          kesepakatan hingga proses produksi dan distribusi yang dilakukan oleh vendor-vendor dan
          supplier barang dan jasa yang ada di Xooply. Dengan begitu, Anda bisa berbelanja dengan
          tenang tanpa khawatir dan takut akan adanya tindak penipuan atau keterlambatan dalam
          proses produksi dan distribusi.
          <br />
          <br />
          <strong>2. Cepat & Praktis</strong>
          <br />
          <br />
          Sistem penunjukan langsung dalam proses belanja barang dan jasa memang terbilang lebih
          cepat dibandingkan sistem lelang. Terlebih lagi, sistem penunjukan langsung melalui Xooply
          dilakukan secara online yang mana tentunya semakin memudahkan Anda dalam melakukan
          penunjukan langsung. Jika pada sistem lelang, Anda harus memberikan waktu tertentu agar
          vendor-vendor atau peserta pengadaan barang dan jasa dapat menyiapkan penawarannya untuk
          Anda, maka dalam sistem penunjukan langsung Anda tidak perlu memberikan waktu tertentu
          tersebut. Anda cukup melihat profil dan ulasan mengenai vendor-vendor yang tersedia di
          Xooply, kualitas dan kuantitas produk mereka, proses produksi mereka, harga serta proses
          distribusi mereka. Selain itu, Anda juga tidak perlu menyebar informasi kepada media-media
          terkait proses belanja barang dan jasa yang ingin dilakukan oleh perusahaan Anda.
          Penyebaran informasi melalui media-media tentunya membutuhkan biaya yang besar.
          <br />
          <br />
          <strong>3. Aman & Terpercaya</strong>
          <br />
          <br />
          Ketika melakukan sistem penunjukan langsung secara online, mungkin Anda khawatir tentang
          penipuan. Namun, melalui situs belanja barang dan jasa Xooply, Anda tidak perlu
          mengkhawatirkan hal tersebut. Karena pihak Xooply secara teliti telah melakukan proses
          screening dan verifikasi dengan baik kepada para vendor yang hendak menawarkan barang dan
          jasa mereka di Xooply. Mereka pun merupakan vendor-vendor yang telah terbukti melayani
          konsumen mereka dengan baik selama bertahun-tahun. Sehingga, Anda tidak perlu khawatir
          akan kualitas serta pelayanan yang akan diberikan kepada Anda. Ayo nikmati sekarang juga
          keuntungan belanja barang dan jasa di Xooply!
          <br />
          <br />
          <h2 className="font-semibold text-base">
            Dapatkan Suplai Barang dan Jasa Terbaik dari Xooply
          </h2>
          <br />
          Bingung kemana harus mencari tempat yang menyediakan suplai barang dan jasa terbaik?
          Xooply jawabannya. Xooply merupakan situs suplai barang dan jasa terbaik dan terpercaya di
          Indonesia. Banyak sekali vendor-vendor ternama di Xooply yang memiliki integritas serta
          kredibilitas tinggi. Bahkan, beberapa dari mereka telah terbukti melayani konsumen mereka
          dengan baik selama bertahun-tahun dalam hal suplai barang dan jasa. Xooply juga semakin
          memudahkan Anda dalam melakukan suplai barang dan jasa karena sudah bisa diakses secara
          online. Selain itu juga, pilihan metode pembayaran yang banyak serta ketersediaan barang
          dan jasa yang banyak juga makin memudahkan Anda dalam mencari kebutuhan-kebutuhan Anda.
          Xooply juga memiliki fitur tracking and monitoring yang tentunya memberikan Anda rasa aman
          dan nyaman bagi Anda ketika melakukan transaksi suplai barang dan jasa di sini.
          <br />
          <br />
          Xooply juga tercatat telah berhasil memudahkan beberapa transaksi suplai barang dan jasa
          dari beberapa perusahaan swasta. Alhasil, beberapa vendor dan brand ternama telah
          bergabung menjadi supplier di Xooply. Mereka menawarkan barang dan jasa yang sangat
          berkualitas yang tentunya bisa menjadi pilihan terbaik bagi perusahaan Anda. Bahkan, Anda
          bisa melihat beberapa ulasan yang telah diberikan oleh konsumen lainnya sehingga bisa
          meningkatkan kepercayaan Anda untuk melakukan transaksi suplai barang dan jasa di Xooply.
          Jadi, bagi Anda yang ingin mendapatkan kemudahan dalam melakukan transaksi suplai barang
          dan jasa, segera nikmati fitur yang beragam dari Xooply sekarang juga!
          <br />
        </div>
        <div className="mt-3">
          <span
            onClick={toggleReadMore}
            className="font-medium text-theme-primary-main text-sm py-2 cursor-pointer"
          >
            {isReadMore ? 'Lihat Selengkapnya' : 'Lihat Lebih Sedikit'}
          </span>
        </div>
      </section>
    </>
  );
};

export default SeoFooter;
